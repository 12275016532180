@import "navbar.css";
@import "home.css";
@import "footer.css";
@import "about.css";
@import "contact.css";
@import "header.css";
@import "illusztraciok.css";
@import "image_viewer.css";
@import "animations.css";
@import "anim_viewer.css";
@import "szolgaltatasok.css";

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body{
    font-family: 'Quicksand', sans-serif;
    color: #04081a;
    user-select: none;
    -webkit-user-select:none;
    -webkit-touch-callout:none;
}

p{
    margin-bottom: 20px;
}

.App{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100vh;
}

h1, h2, h3{
    font-weight: 300;
}

hr{
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

i{
    font-size: 30px;
    padding: 10px;
}

.content-container{
    margin: 0 auto;
    width: 1200px;
}

.color-primary{
    color: #009799;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
background: #04081a;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
background: #04081a;
}

a{
    text-decoration: none;
    color: inherit;
}

a:hover{
    color: #306189;
    transition: color 0.3s;
}

input, textarea{
    border: 1px solid rgba(0, 0, 0, 0.1);
    font: inherit;
    outline: none;
    padding: 10px;
    margin: 10px 0;
    color: inherit;
}

input:focus, textarea:focus, input:hover, textarea:hover{
    border-color: #306189;
}

textarea{
    resize: vertical;
}

button{
    border: none;
    border-radius: 10px;
    background-color: #306189;
    color: white;
    font: inherit;
    padding: 10px;
    cursor: pointer;
}

button:hover{
    background-color: #318FC4;
}

form{
    display: flex;
    flex-direction: column;
}

.my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: 0; /* gutter size offset */
    width: auto;
}
.my-masonry-grid_column {
    padding-left: 3px; /* gutter size */
    background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
margin-bottom: 3px;
}

.social-icon{
    width: 30px;
}

.selectable-text{
    -moz-user-select: all;
    -webkit-user-select: all;
    -ms-user-select: all;
    user-select: all;
}

/* mobile */
@media only screen and (max-width: 400px) {
    .content-container{
        width: auto;
        margin: 10px 10px;
    }
}