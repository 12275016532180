.navbar{
    background-color: white;
    position: sticky;
    top: 0;
    z-index: 10;
}

.navbar .menu-container{
    padding: 20px 0;
    justify-content: center;
    display: flex;
    justify-content: center;
}

.navbar .menu-container .menu-items-container{
    display: inherit;
}

.navbar .menu-container a{
    padding: 0 20px;
    font-size: 16px;
}

.navbar .menu-container a:hover{
    color: #306189;
}

.active-page{
    color: #306189;
    font-weight: 900;
}

.mobile-navbar{
    position: fixed;
    top: 0;
    font-size: 25px;
    width: 100%;
    z-index: 10;
}

.mobile-nav-button{
    background-color: white;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin: 10px;
    position: absolute;
    z-index: 100;
    color: #306189;
}

.mobile-navbar .menu-items{
    background-color: white;
    padding-bottom: 20px;
    padding-top: 14px;
    padding-left: 63px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.233);
}

.mobile-navbar .menu-items div{
    margin-bottom: 10px;
}

.close-menu-box{
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 360px;
    z-index: 100;
}