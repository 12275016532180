.navbar{
    background-color: white;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 10;
}

.navbar .menu-container{
    padding: 20px 0;
    justify-content: center;
    display: flex;
    justify-content: center;
}

.navbar .menu-container .menu-items-container{
    display: inherit;
}

.navbar .menu-container a{
    padding: 0 20px;
    font-size: 16px;
}

.navbar .menu-container a:hover{
    color: #306189;
}

.active-page{
    color: #306189;
    font-weight: 900;
}

.mobile-navbar{
    position: fixed;
    top: 0;
    font-size: 25px;
    width: 100%;
    z-index: 10;
}

.mobile-nav-button{
    background-color: white;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin: 10px;
    position: absolute;
    z-index: 100;
    color: #306189;
}

.mobile-navbar .menu-items{
    background-color: white;
    padding-bottom: 20px;
    padding-top: 14px;
    padding-left: 63px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.233);
}

.mobile-navbar .menu-items div{
    margin-bottom: 10px;
}

.close-menu-box{
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 360px;
    z-index: 100;
}
.image-box{
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
}

.image-box img,
.image-box video{
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}
.footer{
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.about-container{
    display: flex;
    flex-direction: column;
}

.about-container img{
    width: 250px;
    border-radius: 50%;
    align-self: center;
}

.app-list-container{
    display: flex;
    justify-content: center;
}

.app-list-container img{
    border-radius: 0;
    width: 60px;
    align-self: center;
    justify-self: center;
    margin-bottom: 10px;
}

.app-logo-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* border: 1px solid rgba(255, 0, 0, 0.171); */
    width: 100px;
    margin: 0 10px;
    text-align: center;
}

.app-logo-container p{
    margin: 0 !important;
    padding: 0 !important;
    font-size: 12px;
}

.about-container h3{
    margin: 20px 0;
    background-color: #306189;
    color: white;
    padding: 10px 5px;
    font-weight: 500;
}

.about-container ul{
    margin-left: 18px;
}

.about-container ul ul{
    list-style: none;
}

.about-container .about-text{
    text-align: justify;
}

.about-container p {
    margin: 20px 0;
}

.about-container a {
    text-decoration: underline;
    font-style: italic;
}

@media only screen and (max-width: 400px) {
    .about-container{
        display: flex;
        flex-direction: column;
    }

    .app-list-container{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 10px;
    }

    .app-logo-container{
        flex-direction: row !important;
        text-align: left;
    }

    .app-list-container img{
        margin: 0;
        margin-right: 10px;
    }

    .about-container .about-text{
        text-align: center;
    }
}
.contact-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-container form{
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 20px;
    -webkit-filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.03));
            filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.03));
    background-color: white;
}

.contact-container button{
    align-self: flex-end;
}

.contact-container .selectable-text{
    font-size: 20px;
    margin-top: 10px;
}

.contact-container .title{
    padding-bottom: 10px;
}


.contact-container h1{
    padding: 20px;
}

.contact-container h3{
    text-align: center;
}

.contact-container .name-email-container{
    display: flex;
}

.contact-container .name-email-container input{
    width: 100%;
}

.name-field{
    margin-right: 20px;
}

@media only screen and (max-width: 400px){
    .contact-container .name-email-container{
        flex-direction: co;
    }

    .contact-container h2{
        text-align: center;
    }
}
.header{
    padding-top: 30px;
    display: flex;
    justify-content: space-between;
}

.header .profile-picture{
    width: 200px;
    height: 200px;
    margin-right: 26px;
}

.header h1{
    font-size: 60px;
}

.header .title-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.header .title-container img{
    width: 540px;
}

.social-icons-container{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 10px 0;
}

.social-icons-container div{
    margin: 0 5px
}

.header .profile-container{
    display: inherit;
}

/* mobile */
@media only screen and (max-width: 400px) {
    .header{
        flex-direction: column;
    }

    .profile-container{
        flex-direction: column;
        align-items: center;
    }

    .social-icons-container{
        justify-content: center;
    }

    .title-container img{
        width: 100% !important;
    }
}
.illusztraciok-container .images-container{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
    grid-gap: 2px;
}

.illusztraciok-container .images-container .image-box{
    height: 500px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
}

.image-box .info-box{
    background-color: rgba(0, 0, 0, 0.596);
    height: 100%;
    color: white;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.illusztraciok-container h1{
    text-align: center;
    margin-bottom: 30px
}

@media only screen and (max-width: 400px) {
    .illusztraciok-container .images-container{
        display: flex;
        flex-direction: column;
    }
}
.image-viewer-container{
    position: fixed;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    width: 100%;
    top: 0;
    bottom: 0;
    z-index: 100;
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.image-viewer-container .details-container{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 90% auto;
    position: relative;
}

.image-viewer-container .details-container img{
    object-fit: contain;
    max-width: 90%;
    max-height: 100%;
    position: absolute;
}

.image-viewer-container .details-container video{
    object-fit: contain;
    max-width: 90%;
    max-height: 100%;
    position: absolute;
}

.image-viewer-container .image-container{
    display: flex;
    max-width: 100%;
    max-height: 100%;
    position: relative;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.details-container .close-button{
    position: absolute;
    right: 0;
    width: 30px;
    height: 30px;
    color: white;
    cursor: pointer;
}

.details-container .left-button, .right-button{
    position: absolute;
    align-self: center;
    cursor: pointer;
    font-size: 60px;
    opacity: 0.5;
    z-index: 10;
}

.details-container .right-button{
    right: 0;
}

.details-container .text-container{
    padding: 10px 50px;
}

.text-container a {
    color: white !important;
    text-decoration: underline;
}

@media only screen and (max-width: 400px) {
    .image-viewer-container .details-container {
        grid-template-rows: 75% auto;
    }

    .left-button, .right-button{
        font-size: 40px !important;
    }
  }
.animation-card{
    position: relative;
    display: inherit;
}


.animation-card .video-play-icon{
    position: absolute;
    color: white;
    right: 0;
    top: -3px;
    z-index: 1;
    pointer-events: none;
    font-size: 20px;
}

.animation-card .more-image{
    position: absolute;
    color: white;
    right: 10px;
    top: 7px;
    z-index: 1;
    pointer-events: none;
    width: 20px;
}

.animation-card .title{
    opacity: 0;
}

.animation-card .title{
    position: absolute;
    pointer-events: none;
    z-index: 1;
    bottom: 10px;
    left: 10px;
    color: white;
}

.animation-card:hover .title{
    opacity: 1;
    transition: opacity 0.5s
}

.animation-card:hover .black-bg{
    opacity: 0.6;
    transition: opacity 0.3s;
}

.animation-card .black-bg{
    background-color: black;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    opacity: 0;
    pointer-events: none;
}
.anim-details-container {
    width: 100%;
    height: 100vh;
    padding: 50px;
    display: grid; 
    grid-template-columns: 1.5fr 0.5fr; 
    grid-template-rows: 1.8fr 0.2fr; 
    grid-gap: 10px 10px; 
    gap: 10px 10px; 
    grid-template-areas: 
      "player description"
      "image-strip ."; 
}

.anim-details-container i{
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    z-index: 10;
}

.anim-details-container video,
.anim-details-container img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.anim-details-container .player{
    background-color: black;
    grid-area: player;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.anim-details-container .description{
    grid-area: description;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.anim-details-container .description a {
    text-decoration: underline;
    color: white;
}

.anim-details-container .anim-image-strip{
    grid-area: image-strip;
    display: flex;
    flex-wrap: wrap;
}

.anim-details-container .anim-image-strip img,
.anim-details-container .anim-image-strip video {
    width: 125px;
    object-fit: cover;
    margin-right: 10px;
    border: 3px solid transparent;
    cursor: pointer;
}

.anim-details-container .anim-image-strip .portrait{
    max-height: 110px;
    object-position: top;
}

.anim-image-strip .current-clip{
    border: 3px solid white !important
}

/* Mobile Styles */
@media only screen and (max-width: 400px) {
    .anim-details-container{
        padding: 10px;
    }

    .anim-details-container{
        display: flex;
        flex-direction: column;
    }

    .anim-image-strip img{
        width: 100px;
    }
}
.services{
    display: flex;
    flex-direction: column;
    max-width: 900px;
}

.services .card-container{
    display: grid;
    grid-template-columns: 430px auto;
    grid-gap: 40px;
    background-color: white;
}

.services .card-container:hover{
    box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.1);
}

.services .card-container img,
.services .card-container video
{
    width: 430px;
    height: 430px;
    object-position: center;
    object-fit: cover;
}

.services .card-container .image-right{
    order: 1
}

.services .card-container h1{
    font-weight: bold;
    color: #306189;
    font-size: 35px;
}

.services .card-container p{
    font-size: 20px;
}

.services .card-container .content{
    display: flex;
    flex-direction: column;
    align-self: center;
    padding-right: 30px;
}

.services .card-container .content.right{
    align-items: flex-end;
    text-align: right;
    padding-right: 0;
    padding-left: 30px;
}

@media only screen and (max-width: 400px) {
    .services .card-container{
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
    }

    .services .card-container .image-right{
        order: 0;
    }

    .services .card-container .content,
    .services .card-container .content.right
    {
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 0
    }

    .services .card-container video,
    .services .card-container img{
        width: 350px;
        height: 350px;
        align-self: center;
    }
}
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body{
    font-family: 'Quicksand', sans-serif;
    color: #04081a;
    user-select: none;
    -webkit-user-select:none;
    -webkit-touch-callout:none;
}

p{
    margin-bottom: 20px;
}

.App{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100vh;
}

h1, h2, h3{
    font-weight: 300;
}

hr{
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

i{
    font-size: 30px;
    padding: 10px;
}

.content-container{
    margin: 0 auto;
    width: 1200px;
}

.color-primary{
    color: #009799;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
background: #04081a;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
background: #04081a;
}

a{
    text-decoration: none;
    color: inherit;
}

a:hover{
    color: #306189;
    transition: color 0.3s;
}

input, textarea{
    border: 1px solid rgba(0, 0, 0, 0.1);
    font: inherit;
    outline: none;
    padding: 10px;
    margin: 10px 0;
    color: inherit;
}

input:focus, textarea:focus, input:hover, textarea:hover{
    border-color: #306189;
}

textarea{
    resize: vertical;
}

button{
    border: none;
    border-radius: 10px;
    background-color: #306189;
    color: white;
    font: inherit;
    padding: 10px;
    cursor: pointer;
}

button:hover{
    background-color: #318FC4;
}

form{
    display: flex;
    flex-direction: column;
}

.my-masonry-grid { /* Not needed if autoprefixing */ /* Not needed if autoprefixing */
    display: flex;
    margin-left: 0; /* gutter size offset */
    width: auto;
}
.my-masonry-grid_column {
    padding-left: 3px; /* gutter size */
    background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
margin-bottom: 3px;
}

.social-icon{
    width: 30px;
}

.selectable-text{
    -webkit-user-select: all;
    user-select: all;
}

/* mobile */
@media only screen and (max-width: 400px) {
    .content-container{
        width: auto;
        margin: 10px 10px;
    }
}
