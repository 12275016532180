.about-container{
    display: flex;
    flex-direction: column;
}

.about-container img{
    width: 250px;
    border-radius: 50%;
    align-self: center;
}

.app-list-container{
    display: flex;
    justify-content: center;
}

.app-list-container img{
    border-radius: 0;
    width: 60px;
    align-self: center;
    justify-self: center;
    margin-bottom: 10px;
}

.app-logo-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* border: 1px solid rgba(255, 0, 0, 0.171); */
    width: 100px;
    margin: 0 10px;
    text-align: center;
}

.app-logo-container p{
    margin: 0 !important;
    padding: 0 !important;
    font-size: 12px;
}

.about-container h3{
    margin: 20px 0;
    background-color: #306189;
    color: white;
    padding: 10px 5px;
    font-weight: 500;
}

.about-container ul{
    margin-left: 18px;
}

.about-container ul ul{
    list-style: none;
}

.about-container .about-text{
    text-align: justify;
}

.about-container p {
    margin: 20px 0;
}

.about-container a {
    text-decoration: underline;
    font-style: italic;
}

@media only screen and (max-width: 400px) {
    .about-container{
        display: flex;
        flex-direction: column;
    }

    .app-list-container{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 10px;
    }

    .app-logo-container{
        flex-direction: row !important;
        text-align: left;
    }

    .app-list-container img{
        margin: 0;
        margin-right: 10px;
    }

    .about-container .about-text{
        text-align: center;
    }
}