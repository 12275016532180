.illusztraciok-container .images-container{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
    grid-gap: 2px;
}

.illusztraciok-container .images-container .image-box{
    height: 500px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
}

.image-box .info-box{
    background-color: rgba(0, 0, 0, 0.596);
    height: 100%;
    color: white;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.illusztraciok-container h1{
    text-align: center;
    margin-bottom: 30px
}

@media only screen and (max-width: 400px) {
    .illusztraciok-container .images-container{
        display: flex;
        flex-direction: column;
    }
}