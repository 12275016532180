.animation-card{
    position: relative;
    display: inherit;
}


.animation-card .video-play-icon{
    position: absolute;
    color: white;
    right: 0;
    top: -3px;
    z-index: 1;
    pointer-events: none;
    font-size: 20px;
}

.animation-card .more-image{
    position: absolute;
    color: white;
    right: 10px;
    top: 7px;
    z-index: 1;
    pointer-events: none;
    width: 20px;
}

.animation-card .title{
    opacity: 0;
}

.animation-card .title{
    position: absolute;
    pointer-events: none;
    z-index: 1;
    bottom: 10px;
    left: 10px;
    color: white;
}

.animation-card:hover .title{
    opacity: 1;
    transition: opacity 0.5s
}

.animation-card:hover .black-bg{
    opacity: 0.6;
    transition: opacity 0.3s;
}

.animation-card .black-bg{
    background-color: black;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    opacity: 0;
    pointer-events: none;
}