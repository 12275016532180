.anim-details-container {
    width: 100%;
    height: 100vh;
    padding: 50px;
    display: grid; 
    grid-template-columns: 1.5fr 0.5fr; 
    grid-template-rows: 1.8fr 0.2fr; 
    gap: 10px 10px; 
    grid-template-areas: 
      "player description"
      "image-strip ."; 
}

.anim-details-container i{
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    z-index: 10;
}

.anim-details-container video,
.anim-details-container img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.anim-details-container .player{
    background-color: black;
    grid-area: player;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.anim-details-container .description{
    grid-area: description;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.anim-details-container .description a {
    text-decoration: underline;
    color: white;
}

.anim-details-container .anim-image-strip{
    grid-area: image-strip;
    display: flex;
    flex-wrap: wrap;
}

.anim-details-container .anim-image-strip img,
.anim-details-container .anim-image-strip video {
    width: 125px;
    object-fit: cover;
    margin-right: 10px;
    border: 3px solid transparent;
    cursor: pointer;
}

.anim-details-container .anim-image-strip .portrait{
    max-height: 110px;
    object-position: top;
}

.anim-image-strip .current-clip{
    border: 3px solid white !important
}

/* Mobile Styles */
@media only screen and (max-width: 400px) {
    .anim-details-container{
        padding: 10px;
    }

    .anim-details-container{
        display: flex;
        flex-direction: column;
    }

    .anim-image-strip img{
        width: 100px;
    }
}