.services{
    display: flex;
    flex-direction: column;
    max-width: 900px;
}

.services .card-container{
    display: grid;
    grid-template-columns: 430px auto;
    grid-gap: 40px;
    background-color: white;
}

.services .card-container:hover{
    box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.1);
}

.services .card-container img,
.services .card-container video
{
    width: 430px;
    height: 430px;
    object-position: center;
    object-fit: cover;
}

.services .card-container .image-right{
    order: 1
}

.services .card-container h1{
    font-weight: bold;
    color: #306189;
    font-size: 35px;
}

.services .card-container p{
    font-size: 20px;
}

.services .card-container .content{
    display: flex;
    flex-direction: column;
    align-self: center;
    padding-right: 30px;
}

.services .card-container .content.right{
    align-items: flex-end;
    text-align: right;
    padding-right: 0;
    padding-left: 30px;
}

@media only screen and (max-width: 400px) {
    .services .card-container{
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
    }

    .services .card-container .image-right{
        order: 0;
    }

    .services .card-container .content,
    .services .card-container .content.right
    {
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 0
    }

    .services .card-container video,
    .services .card-container img{
        width: 350px;
        height: 350px;
        align-self: center;
    }
}