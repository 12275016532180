.header{
    padding-top: 30px;
    display: flex;
    justify-content: space-between;
}

.header .profile-picture{
    width: 200px;
    height: 200px;
    margin-right: 26px;
}

.header h1{
    font-size: 60px;
}

.header .title-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.header .title-container img{
    width: 540px;
}

.social-icons-container{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 10px 0;
}

.social-icons-container div{
    margin: 0 5px
}

.header .profile-container{
    display: inherit;
}

/* mobile */
@media only screen and (max-width: 400px) {
    .header{
        flex-direction: column;
    }

    .profile-container{
        flex-direction: column;
        align-items: center;
    }

    .social-icons-container{
        justify-content: center;
    }

    .title-container img{
        width: 100% !important;
    }
}