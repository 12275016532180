.image-viewer-container{
    position: fixed;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    width: 100%;
    top: 0;
    bottom: 0;
    z-index: 100;
    backdrop-filter: blur(10px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.image-viewer-container .details-container{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 90% auto;
    position: relative;
}

.image-viewer-container .details-container img{
    object-fit: contain;
    max-width: 90%;
    max-height: 100%;
    position: absolute;
}

.image-viewer-container .details-container video{
    object-fit: contain;
    max-width: 90%;
    max-height: 100%;
    position: absolute;
}

.image-viewer-container .image-container{
    display: flex;
    max-width: 100%;
    max-height: 100%;
    position: relative;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.details-container .close-button{
    position: absolute;
    right: 0;
    width: 30px;
    height: 30px;
    color: white;
    cursor: pointer;
}

.details-container .left-button, .right-button{
    position: absolute;
    align-self: center;
    cursor: pointer;
    font-size: 60px;
    opacity: 0.5;
    z-index: 10;
}

.details-container .right-button{
    right: 0;
}

.details-container .text-container{
    padding: 10px 50px;
}

.text-container a {
    color: white !important;
    text-decoration: underline;
}

@media only screen and (max-width: 400px) {
    .image-viewer-container .details-container {
        grid-template-rows: 75% auto;
    }

    .left-button, .right-button{
        font-size: 40px !important;
    }
  }