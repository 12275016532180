.contact-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-container form{
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 20px;
    filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.03));
    background-color: white;
}

.contact-container button{
    align-self: flex-end;
}

.contact-container .selectable-text{
    font-size: 20px;
    margin-top: 10px;
}

.contact-container .title{
    padding-bottom: 10px;
}


.contact-container h1{
    padding: 20px;
}

.contact-container h3{
    text-align: center;
}

.contact-container .name-email-container{
    display: flex;
}

.contact-container .name-email-container input{
    width: 100%;
}

.name-field{
    margin-right: 20px;
}

@media only screen and (max-width: 400px){
    .contact-container .name-email-container{
        flex-direction: co;
    }

    .contact-container h2{
        text-align: center;
    }
}