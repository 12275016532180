.image-box{
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
}

.image-box img,
.image-box video{
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}